@font-face {
  font-family: CormorantUnicase;
  font-display: swap;
  src: url("../fonts/CormorantUnicase-Light.woff2") format("woff2"),
    url("../fonts/CormorantUnicase-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: CormorantUnicase;
  font-display: swap;
  src: url("../fonts/CormorantUnicase-Bold.woff2") format("woff2"),
    url("../fonts/CormorantUnicase-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: CormorantUnicase;
  font-display: swap;
  src: url("../fonts/CormorantUnicase-Regular.woff2") format("woff2"),
    url("../fonts/CormorantUnicase-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: CormorantUnicase;
  font-display: swap;
  src: url("../fonts/CormorantUnicase-Medium.woff2") format("woff2"),
    url("../fonts/CormorantUnicase-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: LibreBaskerville;
  font-display: swap;
  src: url("../fonts/LibreBaskerville-Bold.woff2") format("woff2"),
    url("../fonts/LibreBaskerville-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: LibreBaskerville;
  font-display: swap;
  src: url("../fonts/LibreBaskerville-Italic.woff2") format("woff2"),
    url("../fonts/LibreBaskerville-Italic.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: CormorantUnicase;
  font-display: swap;
  src: url("../fonts/CormorantUnicase-SemiBold.woff2") format("woff2"),
    url("../fonts/CormorantUnicase-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  font-display: swap;
  src: url("../fonts/Raleway-Bold.woff2") format("woff2"),
    url("../fonts/Raleway-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: LibreBaskerville;
  font-display: swap;
  src: url("../fonts/LibreBaskerville-Regular.woff2") format("woff2"),
    url("../fonts/LibreBaskerville-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  font-display: swap;
  src: url("../fonts/Raleway-Light.woff2") format("woff2"),
    url("../fonts/Raleway-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  font-display: swap;
  src: url("../fonts/Raleway-Medium.woff2") format("woff2"),
    url("../fonts/Raleway-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  src: url("../fonts/Raleway-Regular.woff2") format("woff2"),
    url("../fonts/Raleway-Regular.woff") format("woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}
