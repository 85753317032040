@mixin breakpoint($point) {
  @if $point == xs {
    @media (max-width: 575.98px) {
      @content;
    }
  } @else if $point == sm {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $point == md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point == lg {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $point == xl {
    @media (min-width: 1200px) {
      @content;
    }
  }
}
