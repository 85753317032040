@import "../../../css/mixins";

.container {
  max-width: 1420px;
  padding: 0 15px;
  margin: 0 auto;

  @include breakpoint(md) {
    padding: 0 30px;
  }
}
