@import "../../../../css/mixins";
@import "../../../../css/vars";

.preparation {
  margin: 1rem;
  position: relative;

  opacity: 0;
  animation: rolling-left 0.5s ease forwards;
  transform: translateX(-100%);

  &:nth-child(odd) {
    animation-delay: 0.6s;
  }

  &:nth-child(even) {
    animation-delay: 0.9s;
  }

  @keyframes rolling-left {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &:hover .overlay {
    opacity: 0.4;
  }

  &:hover .title {
    color: $clr_gold_alt;
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $clr_overlay;
  }

  @include breakpoint(lg) {
    margin: 2rem;
  }

  .wrapper_image {
    width: 290px;
    height: 250px;
    margin: auto;
    background-color: clr_skeleton;

    @include breakpoint(xs) {
      width: 350px;
      height: 280px;
    }

    @include breakpoint(sm) {
      width: 390px;
      height: 320px;
    }

    @include breakpoint(md) {
      width: 400px;
      height: 330px;
    }

    @include breakpoint(lg) {
      width: 460px;
      height: 370px;
    }

    .image {
      border: 1px solid $clr_gold;
      object-fit: cover;
      width: 100%;
      height: 100%;
      margin-bottom: -4px;

      @include breakpoint(lg) {
        border: 1px solid $clr_gold_alt;
      }
    }
  }

  .title {
    width: max-content;
    position: absolute;
    z-index: 2;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0.8px;
    line-height: 18px;

    @include breakpoint(md) {
      font-size: 22px;
    }

    @include breakpoint(lg) {
      font-size: 24px;
    }
  }

  .btn {
    position: absolute;
    z-index: 2;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
    width: 220px;
  }
}
