@import "../../../../css/mixins";
@import "../../../../css/vars";

.container {
  display: flex;
  flex-direction: column;

  .info {
    display: grid;
    grid-template-columns: 40px 1fr;
    font-family: LibreBaskerville, serif;
    letter-spacing: 1px;

    margin-top: 10px;
    margin-right: 15px;

    @include breakpoint(md) {
      grid-template-columns: 50px 1fr;
    }

    .icon {
      width: 100%;
      object-fit: cover;
      fill: $clr_gold;

      &:hover {
        fill: $clr_gold_alt;
        animation: up-down 0.7s linear forwards;
      }

      @keyframes up-down {
        from {
          transform: translateY(-20px);
        }
        to {
          transform: translateX(0);
        }
      }
    }

    .text {
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
      margin-bottom: 10px;
      margin-left: 15px;

      @include breakpoint(sm) {
        font-size: 23px;
      }

      @include breakpoint(md) {
        font-size: 26px;
      }

      .subtext {
        margin-top: 5px;
        font-size: 16px;
        font-weight: 600;
        font-family: CormorantUnicase, serif;
        letter-spacing: 0.8px;

        @include breakpoint(md) {
          font-size: 20px;
        }
      }
    }
  }
}
