@import "../../css/mixins";
@import "../../css/vars";

.modal {
  background-color: $clr_overlay;
  z-index: 99;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;

  animation-name: showModal;
  animation-duration: 0.5s;
  animation-timing-function: easy-out;

  @keyframes showModal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100%;
    }
  }

  .content {
    font-family: CormorantUnicase, serif;
    background-color: $clr_modal_body;
    width: 300px;
    height: 200px;
    letter-spacing: 0.5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    border: 2px solid $clr_gold_alt;
    box-shadow: 0 4px 16px $clr_grey;
    transition: all 0.5s;

    position: relative;

    animation-name: showModalContent;
    animation-duration: 0.5s;
    animation-timing-function: easy-out;

    @include breakpoint(md) {
      width: 340px;
      height: 250px;
    }

    @keyframes showModalContent {
      0% {
        opacity: 0;
        transform: translateY(-300px);
      }
      100% {
        opacity: 100%;
        transform: translateY(0px);
      }
    }

    & * {
      padding: 10px 20px;
    }

    // Close button
    .close {
      position: absolute;
      top: 2px;
      right: -5px;
      opacity: 0.3;
      width: 20px;
      height: 20px;

      padding: 0;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      &:before,
      &:after {
        content: " ";
        position: absolute;
        top: 0px;
        left: 0px;
        height: 20px;
        width: 2px;
        background-color: $clr_grey_alt;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 0;

      position: relative;
      color: $clr_milk;

      .headling {
        font-size: 18px;
        color: $clr_gold_alt;
        border-bottom: 1px solid $clr_gold;
        width: 100%;
        padding-top: 0;
        text-align: center;

        @include breakpoint(md) {
          font-size: 22px;
        }
      }

      .text {
        font-size: 16px;
        text-align: center;
        white-space: pre-wrap;

        @include breakpoint(md) {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
    .btn {
      max-width: 250px;
    }
  }
}

.modal_hidden {
  opacity: 0;
  .content {
    opacity: 0;
    transform: translateY(-300px);
  }
}
