@import "../../../css/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.2rem;
  height: 100%;
  max-height: 50rem;

  opacity: 0;
  animation: dropdown 1s ease-in forwards;
  transform: translateY(-30px);

  @include breakpoint(md) {
    height: 75%;
    flex-direction: row;
    justify-content: center;
  }

  @keyframes dropdown {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
