@import "../../../css/vars";
@import "../../../css/mixins";

.card {
  padding: 10px;
  margin-bottom: 2rem;
  max-width: 800px;
  width: 300px;

  border: 2px solid $clr_gold;
  border-radius: 0px;
  background-color: $clr_bg_alt;
  box-shadow: 0 4px 8px $clr_milk;

  display: flex;
  flex-direction: column;

  @include breakpoint(sm) {
    width: 450px;
  }

  @include breakpoint(md) {
    margin-bottom: 4rem;
    width: 620px;
  }

  @include breakpoint(lg) {
    margin-bottom: 4rem;
    width: 800px;
  }

  .title {
    font-size: 18px;
    color: $clr_white;
    word-break: break-word;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 1rem;
    letter-spacing: 1px;
    text-align: center;

    @include breakpoint(sm) {
      font-size: 22px;
      margin-bottom: 2rem;
      font-weight: 600;
    }

    @include breakpoint(md) {
      font-size: 26px;
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-bottom: 1rem;

    @include breakpoint(md) {
      grid-template-columns: 1fr 2fr;
      grid-gap: 20px;
      margin-bottom: 1.5rem;
    }

    .image {
      display: block;
      margin: 0 auto;
      width: 72px;
      height: 72px;
      max-width: 250px;
      object-fit: cover;

      opacity: 0;
      animation: fade-out 1.5s ease-in-out forwards;

      @include breakpoint(sm) {
        width: 130px;
        height: auto;
      }

      @include breakpoint(md) {
        width: 150px;
      }

      @keyframes fade-out {
        from {
          opacity: 0;
          transform: translateY(30px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  .link {
    display: flex;
    justify-content: center;
  }
}
