@import "../../../css/mixins";
@import "../../../css/vars";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .wrapperText {
    background-color: $clr_bg_nav;
    padding: 1rem;
    margin-bottom: 2rem;

    .text {
      font-family: LibreBaskerville, serif;
      letter-spacing: 0.5px;
      color: $clr_milk;
      font-size: 16px;
      margin-bottom: 1.1rem;
      padding-bottom: 10px;
      padding-left: 10px;
      border-bottom: 1px solid $clr_gold_alt;

      @include breakpoint(md) {
        font-size: 20px;
        margin-bottom: 1.5rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        fill: $clr_gold_alt;
        width: 24px;
        height: 22px;

        @include breakpoint(md) {
          padding-right: 5px;
        }
      }

      &_word {
        font-family: CormorantUnicase, serif;
        font-weight: 700;

        font-size: 18px;
        color: $clr_gold_alt;
        @include breakpoint(md) {
          font-size: 20px;
        }
      }
    }
  }
}
