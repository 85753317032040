@import "../../../css/mixins";
@import "../../../css/vars";

.wrapper {
  text-align: right;
  margin-top: 1rem;
  margin: 0 auto;

  font-family: LibreBaskerville, serif;

  @include breakpoint(md) {
    margin-top: 2rem;
    margin-right: 2rem;
  }

  .gradientLine {
    position: relative;
    padding: 1rem;

    background-image: url("./img/info3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;

    z-index: -2;

    @include breakpoint(md) {
      height: 280px;
      margin-left: 30px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.75);
      filter: blur(7px);
    }

    .wrapper_logo {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: space-between;

      .logo {
        width: auto;

        @include breakpoint(sm) {
          width: 350px;
        }

        @include breakpoint(md) {
          width: 400px;
        }

        @include breakpoint(lg) {
          width: 450px;
        }
      }

      .mini_logo {
        display: none;
        width: 90px;
        height: 100%;
        border: 2px solid $clr_gold_alt;
        padding: 10px;
        transition: all 1 ease-in;

        @include breakpoint(sm) {
          margin-left: 20px;
        }

        @include breakpoint(md) {
          width: 100px;
          display: block;
        }

        @include breakpoint(lg) {
          width: 120px;
        }
      }
    }

    .title {
      color: $clr_gold;
      font-size: 1.5rem;
      font-style: italic;
      font-weight: 500;
      margin-top: 0.5rem;
      position: relative;
      z-index: 2;

      @include breakpoint(md) {
        font-size: 2rem;
        margin-top: 1rem;
      }

      @include breakpoint(lg) {
        font-size: 2.5rem;
      }

      span {
        color: $clr_gold_alt;
      }
    }
  }
}
