@import "../../../../css/mixins";
@import "../../../../css/vars";

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;
  margin-left: 25px;

  max-width: 500px;

  line-height: 25px;
  color: $clr_grey_alt;

  @include breakpoint(sm) {
    text-align: left;
    margin-left: 70px;
  }

  @include breakpoint(md) {
    margin-left: 0;
  }

  .text {
    font-size: 18px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    position: relative;
    text-align: center;

    animation: reveal 0.5s ease forwards;
    opacity: 0;
    transform: translateX(-35%);

    &:nth-child(odd) {
      animation-delay: 0.6s;
    }

    &:nth-child(even) {
      animation-delay: 0.8s;
    }

    @keyframes reveal {
      from {
        opacity: 0;
        transform: translateX(-35%);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .subtext {
      z-index: -2;
      color: $clr_milk;
      margin-top: 0;
      text-align: left;
    }

    .subtext:before {
      content: "";
      position: absolute;
      left: -15px;
      top: 9px;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: $clr_gold_alt;
    }
  }
}
