@import "../../../../css/mixins";
@import "../../../../css/vars";

.form {
  display: flex;
  flex-direction: column;

  .fieldset {
    border: 1px solid $clr_gold;
    padding: 10px;
    width: 300px;
    height: auto;
    min-width: 300px;
    max-width: 800px;
    color: $clr_milk;

    & > legend {
      font-family: Raleway, serif;
      font-weight: 400;
    }

    @include breakpoint(sm) {
      width: 340px;
    }

    @include breakpoint(md) {
      width: 720px;
    }

    @include breakpoint(lg) {
      width: 800px;
    }
    box-shadow: 0 4px 8px $clr_gold;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: nowrap;
    width: auto;
    position: relative;
    margin-bottom: 15px;

    @include breakpoint(md) {
      flex-direction: row;
      margin-bottom: 0;
    }

    .block {
      margin-top: 10px;
      margin-bottom: 0;
      width: 320px;

      @include breakpoint(md) {
        margin-top: 0;
        margin-bottom: 10px;
        width: 340px;
      }

      .label {
        font-size: 16px;
        line-height: 25px;

        & > p {
          cursor: pointer;
        }

        @include breakpoint(md) {
          font-size: 18px;
          line-height: 28px;
        }
        .input {
          font-family: Raleway, serif;
          padding: 10px;
          width: 280px;
          height: 60px;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 1px;
          color: $clr_white;
          background-color: $clr_input;

          @include breakpoint(sm) {
            width: 320px;
          }

          @include breakpoint(md) {
            width: 340px;
          }

          &:focus,
          :active {
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;

            box-shadow: 0 2px 4px $clr_milk;
          }
        }
      }

      .error {
        height: 40px;
        margin-top: 10px;
        font-size: 16px;
        color: $clr_error;
        word-break: break-word;
        width: 280px;

        @include breakpoint(sm) {
          width: 320px;
        }
      }
    }
  }

  .agreement {
    font-size: 16px;
    line-height: 25px;
    margin: 10px 0;

    @include breakpoint(sm) {
      margin-left: 5px;
    }

    @include breakpoint(lg) {
      font-size: 18px;
      line-height: 28px;
      margin-left: 25px;
    }

    .checkbox {
      cursor: pointer;
      margin-right: 5px;
      color: $clr_white;
      accent-color: $clr_gold;

      // Создаем кастомный вид
      border: 1px solid $clr_bg;
      border-radius: 2px;
      position: relative;
      overflow: hidden;

      // Создаем белую галочку
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 32%;
        left: 52%;
        width: 23px;
        height: 10px;
        transform: translate(-50%, -50%) rotate(313deg);
        border: 4px solid $clr_white;
        border-top: none;
        border-right: none;
        background-color: transparent;
        opacity: 0;
        transition: opacity 0.5s ease;
      }

      // Показываем галочку, когда checkbox выбран
      &:checked:after {
        opacity: 1;
      }
    }

    &_link {
      border-bottom: 1px solid $clr_white;

      &:hover {
        color: $clr_gold_alt;
        border-bottom: 1px solid $clr_gold_alt;
      }
    }
  }

  .btn_send {
    font-family: CormorantUnicase, serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;

    @include breakpoint(sm) {
      margin: 15px 0;
    }
  }
}
