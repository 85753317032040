@import "../../../../../../css/vars";
@import "../../../../../../css/_mixins";

.dropdown {
  position: relative;

  .link {
    cursor: pointer;
  }

  &:before {
    content: "";
    width: 80px;
    height: 5px;
  }

  .dropdownList {
    animation: all 1.3s ease-in-out;
    padding: 12px;
    color: $clr_white;
    background-color: $clr_bg;
    border: 2px solid $clr_gold;
    width: 280px;
    height: auto;
    z-index: 99;

    position: absolute;
    top: 0;
    right: -100px;
    z-index: 10;

    & li:hover {
      color: $clr_gold_alt;
    }

    @include breakpoint(md) {
      position: fixed;
      top: 58px;
      right: 90px;
    }
  }
}
