@import "../../../css/mixins";
@import "../../../css/vars";

.principles {
  margin-bottom: 2rem;

  .title {
    font-family: LibreBaskerville, serif;
    margin-top: 5rem;
    margin-bottom: 1.5rem;
    font-size: 2rem;

    line-height: 28px;

    @include breakpoint(sm) {
      font-size: 3rem;
    }
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    @include breakpoint(lg) {
      flex-direction: row;
    }

    .technique {
      width: 300px;
      height: 340px;
      margin: 0 auto;
      background-image: url("./img/excavator.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      @include breakpoint(lg) {
        width: 340px;
        height: 390px;
      }
    }
  }

  .block_btn {
    display: flex;
    justify-content: center;
  }
}
