@import "../../../css/_vars";

.header {
  margin-bottom: 1rem;
  padding: 1rem 0;
  background-color: inherit;

  width: 100%;
  height: 100px;
  position: sticky;

  opacity: 0;
  animation: fade-out 1.2s ease-in-out forwards;

  @keyframes fade-out {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .content {
    overflow: hidden;
    color: $clr_white;
    margin: 0 auto;
    max-width: 1920px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;
    z-index: 100;
  }
}
