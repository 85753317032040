@import "../../../../../css/vars";
@import "../../../../../css/mixins";

.navigation {
  top: 0;
  right: 110%;
  padding-top: 40px;
  bottom: 0;
  width: 100%;
  height: 50vh;
  position: fixed;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  text-align: center;

  transform: translate(0);
  transition: 0.3s ease transform;

  @include breakpoint(md) {
    padding-top: 0;
    transform: none;
    flex-direction: row;
    background: transparent;
    width: auto;
    height: 100%;
    position: static;
  }

  .list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(md) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
      margin-right: calc(0.5rem + #{16px});
    }

    .link {
      font-size: 18px;

      @include breakpoint(sm) {
        font-size: 20px;
        font-weight: 600;
      }

      &:hover {
        color: $clr_gold_alt;
      }

      &:not(:last-child) {
        margin-bottom: 32px;
        @include breakpoint(md) {
          margin-bottom: 0;
          margin-right: 16px;
        }
      }
    }
  }

  .active {
    color: $clr_gold;
    cursor: pointer;
    border-bottom: 2px solid $clr_gold;
  }
}

.isMenu {
  transform: translate(110%);
  background: rgb(0, 0, 0);
  opacity: 0.8;
  backdrop-filter: blur(2.8px);
}
