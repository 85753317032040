@import "../../../../../css/_mixins";
@import "../../../../../css/_vars";

.toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  transition: 0.8s ease all;
  position: relative;

  &:hover {
    color: $clr_gold_alt;
  }

  @include breakpoint(md) {
    display: none;
  }
}
