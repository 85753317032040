@import "../../../../css/vars";
@import "../../../../css/mixins";

.wrapper {
  display: flex;
  align-items: center;

  .telephone {
    border: 1px solid $clr_gold;
    padding: 10px;
    width: 36px;
    height: 36px;

    @include breakpoint(sm) {
      width: 51px;
      height: 51px;
    }

    .phone {
      color: $clr_gold;
    }

    &:hover {
      color: $clr_gold_alt;
      border: 1px solid $clr_gold_alt;
    }

    &:hover .phone {
      color: $clr_gold_alt;
      animation: 1.5s ease-in-out 0s normal none running trambling-animation;
    }

    @include breakpoint(sm) {
      padding: 15px;
    }
  }

  .number {
    font-size: 16px;
    margin-left: 10px;
    display: none;

    font-family: LibreBaskerville, serif;

    &:hover {
      color: $clr_gold_alt;
    }

    &:hover .code {
      color: $clr_gold_alt;
    }

    .code {
      color: $clr_gold;
    }

    @include breakpoint(sm) {
      display: block;
    }

    @include breakpoint(md) {
      font-size: 18px;
    }
  }

  @keyframes trambling-animation {
    0%,
    50%,
    100% {
      transform: rotate(0deg);
    }
    10%,
    30% {
      transform: rotate(-10deg);
    }
    20%,
    40% {
      transform: rotate(10deg);
    }
  }
}
