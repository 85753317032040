@import "../../css/mixins";
@import "../../css/vars";

.carousel {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  margin: 2rem 0;
  overflow: hidden;

  @include breakpoint(md) {
    margin: 4rem 0;
  }

  .carousel_inner {
    display: flex;
    flex-wrap: nowrap;
    max-width: 20rem;
    transition: transform 0.5s ease-out;
    overflow-x: hidden;
    align-items: flex-start;

    @include breakpoint(sm) {
      min-width: 25rem;
    }

    @include breakpoint(md) {
      min-width: 30rem;
    }

    .wrapper {
      min-height: 10rem;
      min-width: 20rem;
      padding: 10px 15px;

      position: relative;

      @include breakpoint(md) {
        min-width: 30rem;
        min-height: 25rem;
        padding: 10px 25px;
      }

      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 0 0 auto;

        .image {
          display: block;
          object-fit: cover;
          max-width: 100%;
          max-height: 100%;
          width: 100%;
          height: 100%;
          pointer-events: none;
          border: 2px solid $clr_gold;
          box-shadow: 0 2px 6px $clr_white;

          // opacity: 0.5;
        }

        // item:nth-child(`${currentIndex + 1}`) .image {
        //   opacity: 1;
        // }
      }

      .title {
        width: max-content;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        color: $clr_gold_alt;
        font-size: 22px;
        font-weight: 900;
        letter-spacing: 0.8px;
        line-height: 22px;

        // opacity: 0.5;

        @include breakpoint(md) {
          font-size: 22px;
        }

        @include breakpoint(lg) {
          font-size: 24px;
        }
      }
    }
  }

  .arrow_buttons {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(md) {
      margin-bottom: 2rem;
    }

    .arrow_btn {
      width: 35px;
      height: 35px;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      background-color: inherit;
      border: 1px solid $clr_gold;

      &:hover {
        background-color: $clr_gold;
      }

      &:hover .icon {
        fill: $clr_white;
      }

      @include breakpoint(md) {
        width: 40px;
        height: 40px;
      }

      @include breakpoint(lg) {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      .icon {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 80%;
        fill: $clr_gold;
        cursor: pointer;
      }
    }
  }
}
