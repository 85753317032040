html {
  position: relative;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: CormorantUnicase, serif;
  min-width: 320px;
  min-height: 100vh;
  font-size: 16px;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.85);
  color: rgba(255, 255, 255, 1);
}

footer {
  font-family: Raleway, serif;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

button {
  padding: 0;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
p,
figure,
fieldset {
  margin: 0;
}

iframe {
  border: none;
}

.visually-hidden {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

input {
  background-color: transparent;
  border: none;
  outline: none;
}

input:focus {
  outline: none;
}

textarea {
  background-color: transparent;
  border: none;
  outline: none;
}

textarea:focus {
  outline: none;
}

input[type="file"] {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: inherit;
  color: inherit;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-color: rgba(228, 181, 100, 1);

  width: 20px;
  height: 20px;
}
