@import "../../../../css/mixins";
@import "../../../../css/vars";

.rights {
  font-family: "CormorantUnicase";
  margin-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @include breakpoint(md) {
    margin-top: 80px;
    align-items: center;
  }

  .title {
    font-size: 16px;

    @include breakpoint(md) {
      font-size: 18px;
    }
  }

  .image {
    width: 210px;

    @include breakpoint(md) {
      width: 260px;
    }
  }

  .navigation {
    font-size: 12px;
    margin-top: 5px;
    font-family: Raleway, serif;
    & span {
      color: $clr_gold_alt;
    }

    .link {
      margin-bottom: 6px;
    }
  }
}
