@import "./mixins";
@import "./vars";

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-family: LibreBaskerville, serif;
    font-size: 1.6rem;
    line-height: 34px;
    margin: 1rem 0;
    word-break: break-word;
    letter-spacing: 1px;

    text-align: center;
    z-index: -2;

    opacity: 0;
    animation: fade-out 1.2s ease-in-out forwards;

    @include breakpoint(sm) {
      font-size: 2.5rem;
      margin: 1.5rem 0;
    }

    @include breakpoint(md) {
      line-height: 40px;
      margin: 2rem 0;
      font-size: 3rem;
      text-align: center;
    }

    @include breakpoint(lg) {
      margin: 2.5rem 0;
      font-size: 44px;
    }

    @keyframes fade-out {
      from {
        opacity: 0;
        transform: translateY(-30px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .image {
      color: $clr_gold_alt;
      width: 180px;

      @include breakpoint(sm) {
        width: 220px;
      }

      @include breakpoint(md) {
        width: 250px;
      }
    }

    > br {
      display: block;

      @include breakpoint(sm) {
        display: none;
      }
    }
  }

  .subtitle {
    color: $clr_gold;
    font-size: 20px;
    margin-bottom: 1rem;
    word-break: break-word;
    text-align: center;
    line-height: 22px;
    z-index: -2;
    letter-spacing: 1px;

    opacity: 0;
    animation: fade-in 1.2s ease-in-out forwards;

    @include breakpoint(sm) {
      font-size: 24px;
      line-height: 28px;
    }

    @include breakpoint(md) {
      font-size: 28px;
      margin-bottom: 2rem;
    }

    @include breakpoint(lg) {
      font-size: 34px;
    }

    @keyframes fade-in {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .word {
      color: $clr_gold_alt;
      font-size: 24px;

      @include breakpoint(sm) {
        font-size: 26px;
      }

      @include breakpoint(md) {
        font-size: 32px;
      }

      @include breakpoint(lg) {
        font-size: 38px;
      }
    }
  }

  .home_btn {
    margin-top: 12rem;
  }
}
