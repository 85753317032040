@import "../../css/_mixins";
@import "../../css/vars";

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 260px;
  height: 45px;
  max-width: 340px;
  padding: 0 10px;
  border: none;
  outline: none;

  color: $clr_milk;
  background-color: $clr_gold;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: $clr_white;
    opacity: 0.3;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: $clr_hover;
    transition: all 0.5s ease-in-out;
  }

  &:hover::before {
    width: 100%;
  }

  &:active {
    color: $clr_hover;
    box-shadow: 0 0 4px $clr_white;
  }

  &:focus {
    outline: none;
    box-shadow: 0 2px 6px $clr_btn;
  }

  &:disabled {
    box-shadow: none;
    color: clr_milk;
    background-color: $clr_disabled;
    cursor: none;
    outline: none;
    pointer-events: none;
  }

  @include breakpoint(sm) {
    width: 280px;
  }

  @include breakpoint(lg) {
    width: 300px;
  }

  .text {
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
    text-align: center;

    @include breakpoint(sm) {
      font-size: 20px;
      font-weight: 600;
    }
  }
}
