$clr_bg: rgba(0, 0, 0, 0.85);
$clr_bg_alt: rgba(82, 81, 81, 0.5);
$clr_bg_nav: rgba(12, 12, 12, 0.3);
$clr_white: rgba(255, 255, 255, 1);
$clr_milk: rgba(241, 231, 204, 1);
$clr_gold: rgba(228, 181, 100, 1);
$clr_gold_alt: rgb(255, 166, 13);
$clr_input: rgba(21, 21, 21, 1);
$clr_btn: rgba(242, 241, 236, 1);
$clr_disabled: rgba(77, 77, 77, 1);
$clr_hover: rgba(55, 48, 38, 1);
$clr_error: rgba(251, 99, 99, 1);
$clr_grey: rgba(150, 150, 161, 1);
$clr_grey_alt: rgba(219, 219, 219, 1);
$clr_overlay: rgba(0, 0, 0, 0.6);
$clr_modal_body: rgba(21, 21, 21, 1);
$clr_skeleton: rgba(162, 151, 151, 0.6);
