@import "../../../css/vars";
@import "../../../css/mixins";

.footer {
  background-color: inherit;
  margin: 5rem 0;
  word-break: break-word;

  display: flex;
  justify-content: center;
  flex-direction: column;

  .title {
    font-size: 32px;
    margin-bottom: 30px;
    line-height: 36px;
    font-family: CormorantUnicase, serif;
    letter-spacing: 0.5px;

    @include breakpoint(md) {
      font-size: 50px;
      line-height: 28px;
      margin-bottom: 70px;
    }
  }

  .subtitle {
    font-family: CormorantUnicase, serif;
    color: $clr_gold;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 25px;
    letter-spacing: 1px;

    @include breakpoint(md) {
      font-size: 26px;
    }
  }

  .tender {
    font-family: CormorantUnicase, serif;
    font-size: 16px;
    margin-bottom: 10px;

    @include breakpoint(sm) {
      font-size: 18px;
    }

    @include breakpoint(md) {
      font-size: 20px;
    }
  }
}
