@import "../../../../css/mixins";
@import "../../../../css/vars";

.card {
  margin: 1rem;
  box-shadow: 0 4px 16px $clr_grey;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 400px;
  height: 300px;

  @include breakpoint(md) {
    margin: 2rem;
    height: 400px;
    max-width: 600px;
  }

  .title {
    color: $clr_gold;
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: absolute;
    top: 50%;
    text-align: center;
    word-break: break-word;
    margin: 10px;

    @include breakpoint(md) {
      font-size: 1.6rem;
    }
  }

  &:hover .title {
    color: $clr_white;
  }

  &__btn {
    position: absolute;
    top: 70%;
  }

  .image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover .overlay {
    opacity: 0.4;
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $clr_overlay;
  }

  .dropdown {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $clr_input;
    box-shadow: 0 2px 6px $clr_white;

    .list {
      padding: 1rem;
      z-index: 2;
      font-size: 16px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: 334px;

      @include breakpoint(sm) {
        font-size: 18px;
      }

      @include breakpoint(md) {
        height: 400px;
      }

      .link {
        width: 100%;
        height: 50px;

        @include breakpoint(sm) {
          margin-bottom: 10px;
        }

        .active {
          display: flex;
          justify-content: center;
          align-items: flex-start;

          text-align: center;
          padding: 0.5rem;
          width: 100%;
          height: 100%;
          max-height: 50px;

          @include breakpoint(sm) {
            max-height: 60px;
          }
        }

        .active:hover {
          color: $clr_gold_alt;
          background-color: $clr_input;
          box-shadow: 0 2px 6px $clr_milk;
          cursor: pointer;
        }
      }
    }
  }
}
