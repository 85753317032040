@import "../../../../css/mixins";
@import "../../../../css/vars";

.info {
  display: flex;
  flex-direction: column;
  font-family: LibreBaskerville, serif;

  .text {
    display: flex;
    align-items: center;
    font-size: 16px;

    padding-top: 20px;
    max-width: 330px;

    font-weight: 500;
    line-height: 28px;
    letter-spacing: 1px;
    word-break: break-word;

    @include breakpoint(md) {
      font-size: 20px;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      margin-bottom: 30px;
      max-width: none;

      @include breakpoint(md) {
        margin-bottom: 40px;
      }
    }

    &:hover .image {
      fill: $clr_gold_alt;
    }

    .image {
      fill: $clr_gold;
      margin-right: 10px;
      display: block;
      object-fit: cover;

      @include breakpoint(md) {
        width: 25px;
        height: 25px;
      }
    }
  }
}
