@import "../../../css/mixins";
@import "../../../css/vars";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include breakpoint(lg) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
