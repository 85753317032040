@import "../../css/mixins";
@import "../../css/vars";

.wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  width: 35px;
  height: 35px;
  background-color: $clr_gold;
  border-radius: 50%;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoint(sm) {
    right: 30px;
    width: 40px;
    height: 40px;
  }

  &:hover {
    box-shadow: 0 4px 6px $clr_white;
  }

  .scrollButton {
    display: block;
    width: 18px;
    height: 18px;
    object-fit: cover;

    @include breakpoint(md) {
      width: 22px;
      height: 22px;
    }
  }
}
